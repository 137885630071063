import React from "react";
import { graphql } from "gatsby";
import CareerLinkBox from "../../components/career-link-box";
import PathwaysIntro from "../../components/pathways-intro";
import HeroPage from "../../components/hero-page";
import Pathways from "../../components/pathways";
import Seo from "../../components/seo";

const LogisticsTransportation = ({ data }) => {
  return (
    <>
      <Seo
        title="Logistics and Transportation in North Central Pennsylvania"
        description="The Logistics and Transportation (LT) cluster includes industries related to the storage, transportation and distribution of goods"
        featuredImage={data.opengraphImage.sharp.image}
      />

      <HeroPage
        image={data.heroImage.sharp.fluid}
        title="work"
        caption=""
        position="20%"
      />
      <main>
        <section className="page-intro">
          <div className="intro-title">
            <h2>Logistics and Transportation</h2>

            <p>
              The Logistics and Transportation (LT) cluster includes industries
              related to the storage, transportation and distribution of goods.
            </p>
          </div>

          <div className="intro-text" style={{ alignSelf: "center" }}>
            <p>
              This cluster includes all modes of transportation, among them
              airlines, railroads, trucking companies, as well as logistics
              consulting and other supporting industries.
            </p>

            <p>
              In addition to moving goods, the cluster also contains industries
              related to passenger transportation.
            </p>
          </div>
        </section>

        <CareerLinkBox image={data.careerImage.sharp.image} caption="" />
      </main>

      <PathwaysIntro
        industry="Logistics and Transportation"
        image={data.pathwaysImage.sharp.image}
      />

      <Pathways pathway={data.pathways.pathway} />
    </>
  );
};

export const data = graphql`
  query {
    pathways: pathwaysYaml {
      pathway: logistics {
        name
        associate {
          name
          occupations
          salary
          skills
        }
        diploma {
          name
          occupations
          salary
          skills
        }
        certificate {
          name
          occupations
          salary
          skills
        }
        bachelor {
          name
          occupations
          salary
          skills
        }
      }
    }
    careerImage: file(
      relativePath: { eq: "national-cancer-institute-S-3AnKlICmY-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    pathwaysImage: file(
      relativePath: { eq: "krzysztof-kowalik-pz_z3Tg6fSI-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    heroImage: file(
      relativePath: { eq: "frank-mckenna-tjX_sniNzgQ-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    opengraphImage: file(relativePath: { eq: "frank-mckenna-tjX_sniNzgQ-unsplash.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData(layout: FIXED, width: 1200)
      }
    }
  }
`;

export default LogisticsTransportation;
